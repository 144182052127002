<template>
  <div class="v-login">
    <div class="v-login__wrapper">
      <div class="v-login__box">
        <div v-if="courseDescription" class="v-login__course-description" v-md-html="courseDescription" />
        <div class="v-login__buttons">
          <div class="v-login__button-wrapper">
            <TheButton @click="login">
              {{ $t('LOGIN_STUDENT') }}
            </TheButton>
          </div>
          <div class="v-login__button-wrapper">
            <TheButton @click="login">
              {{ $t('LOGIN_TEACHER') }}
            </TheButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useAuth, useTenant } from '@/core'
import { useHead } from '@vueuse/head'
import { useRouter } from 'vue-router'
import TheButton from '@/components/TheButton'
import useTranslations from '@/composables/useTranslations'

export default {
  name: 'Login',

  components: {
    TheButton
  },

  setup() {
    const { login, instance } = useAuth()
    const { tenant } = useTenant()
    const { getTenantTranslation } = useTranslations()
    const router = useRouter()
    const courseDescription = computed(() => getTenantTranslation(tenant.value.welcomeMessage) || '')

    useHead({
      title: `Login - ${getTenantTranslation(tenant.value.name)}`
    })

    onMounted(async () => {
      try {
        await instance().checkToken()
        router.push('/')
      } catch {
        // Do nothing
      }
    })

    return {
      login,
      courseDescription
    }
  }
}
</script>